import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, retry} from 'rxjs/operators';
import {TicketsPaginationResult} from 'src/app/core/models/ticket/tickets-pagination-result';
import {TicketCategoryGraph} from "../../models/ticket/tickets-category";
import {TicketsFilters, TicketsPaginationFilters} from "../../view-models/tickets-pagination-filters";
import {ErrorHandlerService} from "../error-handler/error-handler.service";
import {SgTablePaginationResult} from "../../../components/sg-components/sg-table/sg-table-pagination-result";
import {Ticket} from "../../models/ticket/ticket";
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GmaoTicketsService {
  baseUrl = `${environment.gmaoBaseURL}/v1/tickets`;
  usersBaseUrl = `${environment.gmaoBaseURL}/v1/users`;
  clientMetaUrl = `${this.baseUrl}/clientMeta`;
  statisticsPath = `${environment.gmaoBaseURL}/v1/statistics/`;
  statisticsActiveCountPath = this.statisticsPath + 'tickets/active/count/';


  getTicketsUrl = `${this.baseUrl}/list`;

  private clientMetaData = new BehaviorSubject(undefined);

  constructor(private http: HttpClient, private handler: ErrorHandlerService) {
  }

  private static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }


  /**
   * GET the list of tickets according to a query
   * @param sort which field to sort by
   * @param direction the direction of the field to sort by
   * @param page how many pages to skip
   * @param limit how many items to get
   * @param filters
   *        search term provided (searching only indexed field - equipment)
   */
  getTickets(
    sort: string = 'createdAt',
    direction: string = 'desc',
    page: number = 0,
    limit: number = 10,
    filters: TicketsFilters
  ): Observable<TicketsPaginationResult> {
    const params = {
      params: new HttpParams()
        .set('sort', sort)
        .set('direction', direction)
        .set('page', (page + 1).toString())
        .set('limit', limit.toString())
    };

    const body = {
     filters: JSON.stringify(filters)
    }

    return (
      this.http
        .post(this.getTicketsUrl, body, params)
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }

  /**
   * GET the list of tickets according to a query
   * @param sort which field to sort by
   * @param direction the direction of the field to sort by
   * @param page how many pages to skip
   * @param limit how many items to get
   * @param filters
   *        search term provided (searching only indexed field - equipment)
   */
  countTickets(locations: string[]): Observable<any> {
    const path = this.baseUrl + '/count';
    let params = new HttpParams()
    params = params.append('filters', JSON.stringify({locations}))
    return (
      this.http
        .get(path, {params})
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }

  /**
   * GET group count ticket value grouped by period
   * @param filters: {startDate:Date,endDate:Date,site:string[]}
   */
  countGroupedTicketsGroupedByPeriod(filters: { startDate: Date, endDate: Date, site: string[] }): Observable<any> {
    const path = this.statisticsPath + 'tickets/count/grouped-by-period';

    const body = {
      startDate: filters.startDate.getTime(),
      endDate: filters.endDate.getTime(),
      building: filters.site.toString()
    }

    return (
      this.http
        .post(path, body)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }

  /**
   * GET group count ticket value grouped by period
   * @param filters: {startDate:Date,endDate:Date,site:string[]}
   */
  countGroupedTicketsByDoneStatus(filters: { startDate: Date, endDate: Date, site: string[] }): Observable<any> {
    const path = this.statisticsPath + 'tickets/count/grouped-by-done-status';

    let body = {
      startDate: `${filters.startDate.getTime()}`,
      endDate: `${filters.endDate.getTime()}`,
      building: filters.site.toString()
    }

    return (
      this.http
        .post(path, body)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }


  /**
   * GET the list of tickets according to a query
   * @param sort which field to sort by
   * @param direction the direction of the field to sort by
   * @param page how many pages to skip
   * @param limit how many items to get
   * @param filters
   * @param fileType
   *        search term provided (searching only indexed field - equipment)
   */
  exportTicketsToFile(
    sort: string = 'createdAt',
    direction: string = 'desc',
    page: number = 0,
    limit: number = 10,
    filters: TicketsFilters,
    fileType: string,
    headers
  ): Observable<any> {
    const body = {
      sort: sort,
      direction: direction,
      page: page + 1, // Increment the page by 1 to match the existing logic
      limit: limit,
      filters: filters,
      fileType: fileType,
      headers: headers
    };

    return (
      this.http
        .post(this.baseUrl + '/exportToFile', body, {responseType: 'blob'})
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }

  // GET
  getUsers(): Observable<any> {
    return (
      this.http
        .get(this.usersBaseUrl)
        // With the new HttpClient, .map is no more.
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    );
  }

  getAssignableGroups(): Observable<any> {
    return (
      this.http
        .get(this.baseUrl + '/groups')
        .pipe(
          catchError(GmaoTicketsService.handleError)
        )
    )
  }

  /**
   * FIXME: Migrate to Boardvisor
   */
  getGroupUserRoles(groupId: string) {
    let path = this.usersBaseUrl + '/group/' + groupId;
    return this.http.get(path).pipe(
      catchError(GmaoTicketsService.handleError)
    )
  }

  getUserById(userId: string): Observable<any> {
    return this.http.get(`${this.usersBaseUrl}/${userId}`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }


  getTicketById(ticketId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${ticketId}`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  // POST
  postTicket(param: any): Observable<any> {
    const body = JSON.stringify(param);
    const headers = {'Content-Type': 'application/json'};
    return this.http.post(this.baseUrl, body, {headers: headers}).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  // PUT
  putTicket(ticketId: string, param: any): Observable<any> {
    const body = JSON.stringify(param);
    return this.http.put(`${this.baseUrl}/${ticketId}`, body).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  // PATCH
  patchTicket(ticketId: string, param: any): Observable<any> {
    const body = JSON.stringify(param);
    const headers = {'Content-Type': 'application/json'};
    return this.http.patch(`${this.baseUrl}/${ticketId}`, body, {headers: headers}).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  // DELETE
  deleteTicket(ticketId: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${ticketId}`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }


  get clientMeta() {
    return this.clientMetaData.asObservable();
  }

  set clientMeta(meta) {
    this.clientMetaData.next(meta);
  }

  getClientMetaData(): Observable<any> {
    return this.http.get(this.clientMetaUrl).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getActiveRequestsCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/active_requests_count`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getActiveRequestsCountByCategory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/active_requests_count_by_category`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getActiveRequestsCountByNature(): Observable<any> {
    return this.http.get(`${this.baseUrl}/active_requests_count_by_nature`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getScheduledInterventionsCount(): Observable<any> {
    return this.http.get(`${this.baseUrl}/scheduled_interventions_count`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getNumberOfTicketsBySubcontractor(): Observable<any> {
    return this.http.get(`${this.baseUrl}/subcontractor_tickets_count`).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getBarStackStats(someType, someGroupBy, filters, incomingStartDate?: Date) {

    let dates: any = {};

    if (filters && filters.hasOwnProperty('realTime') && filters.realTime) {
      dates.startDate = new Date(filters.startDate).getTime() + '';
      dates.endDate = new Date(filters.endDate).getTime() + '';
    } else {
      dates = this.setDates(incomingStartDate);
    }

    let body = {
      type: someType.toLowerCase(),
      groupedBy: someGroupBy.toLowerCase(),
      startDate: dates.startDate,
      endDate: dates.endDate,
      site: filters.site,
    }

    return this.http.post(this.statisticsActiveCountPath, body).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getColumnStackStats(someType, filters, incomingStartDate?: Date) {
    let dates: any = {};

    if (filters && filters.hasOwnProperty('realTime') && filters.realTime) {
      dates.startDate = new Date(filters.startDate).getTime() + '';
      dates.endDate = new Date(filters.endDate).getTime() + '';
    } else {
      dates = this.setDates(incomingStartDate);
    }

    let body = {
      type: someType.toLowerCase(),
      startDate: dates.startDate,
      endDate: dates.endDate,
      site: filters.site,
    }

    return this.http.post<TicketCategoryGraph[]>(this.statisticsActiveCountPath, body).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  getPieStats(someType, filters) {
    const body = {
      type: someType.toLowerCase(),
      startDate: filters?.startDate?.toDateString(),
      endDate: filters?.endDate?.toDateString(),
      site: filters.site,
    }

    return this.http.post(`${environment.gmaoBaseURL}/v1/statistics` + '/tickets/all/count/', body).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }

  /**
   * returns start date and end date values
   * @param incomingStartDate
   */
  private setDates(incomingStartDate) {
    let endDate, startDate;

    if (incomingStartDate && typeof incomingStartDate == 'object') {
      startDate = new Date(incomingStartDate.getFullYear(), 0, 1).getTime() + '';
      endDate = new Date(incomingStartDate.getFullYear(), 11, 31, 23, 59, 59).getTime() + '';
    } else {
      startDate = new Date(new Date().getFullYear(), 0, 1).getTime() + '';
      endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59).getTime() + '';
    }
    return {startDate, endDate};
  }

  getTicketHtmlById(ticketId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${ticketId}/print`, {responseType: 'text'}).pipe(
      catchError(GmaoTicketsService.handleError)
    );
  }


  getFilteredTickets(ticketsPaginationFilters: TicketsPaginationFilters): Observable<SgTablePaginationResult<Ticket>> {
    let params = new HttpParams()
      .append('sort', ticketsPaginationFilters.sortFiled)
      .append('direction', ticketsPaginationFilters.ascending ? "asc" : "desc")
      .append('page', (ticketsPaginationFilters.pageIndex + 1)?.toString())
      .append('limit', ticketsPaginationFilters.pageSize?.toString())
      .append('filters', JSON.stringify(ticketsPaginationFilters.filters));

    return this.http.get<TicketsPaginationResult>(this.baseUrl, {params: params}).pipe(
      map((result: TicketsPaginationResult) => ({
          Items: result.tickets,
          Total: result.count
        })
      ),
      catchError(this.handler.handleError)
    );

  }

}
