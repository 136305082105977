import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HighchartsChartModule} from 'highcharts-angular';
import {
  EnergyEfficiencyEvolutionModule
} from 'src/app/components/energy/energy-efficiency-evo/energy-efficiency-evo.module';
import {
  EnergyEfficiencyStatsModule
} from 'src/app/components/energy/energy-consumption/energy-efficiency-stats/energy-efficiency-stats.module';
import {EnergyEfficiencyModule} from 'src/app/components/energy/energy-efficiency/energy-efficiency.module';
import {EnvImpactModule} from 'src/app/components/global/env-impact/env-impact.module';
import {ReceptionWindowRoutingModule} from './reception-window-routing.module';
import {ReceptionWindowComponent} from './reception-window.component';
import {PipesModule} from '../../core/pipes/pipes.module';
import {
  PieConsumptionSourcesWidgetModule
} from '../../components/gmao-gridster/gridster-item/pie-consumption-sources-widget/pie-consumption-sources-widget.module';
import {
  AreaChartWidgetModule
} from '../../components/gmao-gridster/gridster-item/area-chart-widget/area-chart-widget.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FileUploadModule} from 'ng2-file-upload';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SharedModule} from '../../core/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {FlatpickrModule} from 'angularx-flatpickr';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
  CsvDisplayErrorsDialogModule
} from '../../core/deprecated/deprecated-components/deprecated-csv-display-errors-dialog/csv-display-errors-dialog.module';
import {
  LocationInputModule
} from '../../core/deprecated/deprecated-components/deprecated-location-input/location-input.module';

@NgModule({
  imports: [
    HighchartsChartModule,
    CommonModule,
    PipesModule,
    ReceptionWindowRoutingModule,
    EnvImpactModule,
    EnergyEfficiencyModule,
    EnergyEfficiencyEvolutionModule,
    EnergyEfficiencyStatsModule,
    PieConsumptionSourcesWidgetModule,
    AreaChartWidgetModule,

    // instead of ReceptionScreenModule
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatCheckboxModule,
    SharedModule,
    TranslateModule,
    FlatpickrModule.forRoot(),
    MatDatepickerModule,
    MatRadioModule,
    MatTooltipModule,
    CsvDisplayErrorsDialogModule,
    LocationInputModule
  ],

  declarations: [
    ReceptionWindowComponent
  ],

  exports: [
    ReceptionWindowComponent
  ]


})
export class ReceptionWindowModule {
}
