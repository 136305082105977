import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {environment} from "src/environments/environment";

@Component({
  selector: 'image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent {
  logo: string = environment.logo;
  constructor(
    public dialogRef: MatDialogRef<ImageDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public imgPath: string
  ) {
  }

  close() {
    this.dialogRef.close();
  }
}
