import {Injectable} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from "@angular/router";
import {AuthenticationsService} from "../services/auth/authenticationsService";
import {AuthorizationSpace} from "../models/app/authorizationNameSpace";
import {switchMap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {MenuLinkService} from "../services/menu-link/menu-link.service";
import PagesTypes = AuthorizationSpace.PagesTypes;
import {LocalStorageKeysEnum} from "../services/localstorage/local-storage-keys.enum";
import {LocalStorageService} from "../services/localstorage/local-storage.service";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthenticationsService,
              private localStorageService: LocalStorageService,
              private router:Router,
              private activatedRouter:ActivatedRoute,
              private menuLinkService: MenuLinkService) {
  }


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    let isStandalone = this.authService.checkStandAlone(route) && route.data['canStandalone'];
    let isAuth = this.authService.isAuthenticated();

    if (isStandalone && !this.authService.isAuthenticated()){
      this.router.navigate([`/stand-alone`], { queryParams: { redirectTo: route['_routerState']?.url } });
      return false;
    } else if(isStandalone && this.authService.isAuthenticated() && route['_routerState']?.url.includes('dashboard')){
      window.location.href = route['_routerState']?.url.replace('dashboard', 'standalone-home-page');
      return false;
    }

    if (!isAuth) {
      this.authService.signOut();
      return false;
    }

    return this.menuLinkService.getAuthorizedPagesObs.pipe(
      switchMap((pageTypes: PagesTypes[]) => {
        let isPageAllowed = pageTypes.includes(route.data['pageType']) || route.data['pageType'] == 'ALLOWED'
        const authorized = isPageAllowed || isStandalone;

        if (!authorized) {
          this.authService.signOut();
        }

        return of(authorized);
      })
    );
  }
}
