// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  applicationViewDateFormat:'dd-MM-YYYY HH:mm',
  applicationAPIsDateFormat:'yyyy-MM-dd',
  applicationTimeFormat:'HH:mm',
  emailPattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
  passwordPattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[ !"#$%&\'()*+,-./:;<=>?@\\\\[\\]^_`{|}~]).{8,}$',
  numbersPattern: '^[0-9]+$',
  logo:'assets/Smart-Globe.png',
  name: 'local',
  sentryDsn: 'SENTRY_DSN_CONF',
  monitorLogBySentry: 'disabled',
  gmaoBaseURL: '/api/gmao',
  googleAPIKey:"AIzaSyDPhdTNUHUVBvh4Zngt84L--uLML_G2GtQ",
  mapConfig: {
    credentials: {
        key: "4f0d6ecc05d340"
    },
    apiServer: {
        baseURL: "https://eu1.locationiq.com/v1/search.php"
    },
    rules: {
        format: "json",
        limit: 50,
        addressdetails: 1,
        language: "<ISO 639-1 fr>",
        countrycodes: "<ISO_3166-1_alpha-2 fr>"
    }
  },
  subDomains: "qa-8,qa-8v2,app,app-staging",
  defaultImages:{
    defaultLogo: 'assets/default-logos/smartglobe/Smartglobe-G.svg',
    defaultBackground: 'assets/background.jpg',
    defaultSmartkizLogo: 'assets/default-logos/smartkiz/Smartkiz-S.svg',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
