import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InterceptorService} from './services/interceptor/interceptor.service';
import {SnackBarManager} from './deprecated/deprecated-components/snack-bar-manager';
import {DeprecatedAlarmService} from './deprecated/deprecated-services/deprecated-alarms/deprecated-alarm.service';
import {BuildingService} from './deprecated/deprecated-services/deprecated-building/building-service.service';
import {GreeniotService} from './deprecated/deprecated-services/deprecated-greeniot/greeniot.service';
import {DashboardService} from './services/dashboard/dashboard.service';
import {
  SchedulingMessagesService
} from './deprecated/deprecated-services/deprecated-schduling/scheduling-messages.service';
import {TesseractService} from './deprecated/deprecated-services/deprecated-tesseract/tesseract.service';
import {TreeService} from './deprecated/deprecated-services/deprecated-nodes-tree/tree.service';


@NgModule({
  providers: [
    GreeniotService,
    TesseractService,
    TreeService,
    DashboardService,
    DeprecatedAlarmService,
    BuildingService,
    SchedulingMessagesService,
    SnackBarManager,
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (cookie, translate) => {
        const langCookie = cookie.get('language') || 'fr';
        if (langCookie) {
          translate.setDefaultLang('fr');
          translate.use(langCookie);
          return langCookie;
        }

        const boardvisorData = JSON.parse(localStorage.getItem('boardvisor'));

        if (boardvisorData) {
          const boardvisorArr = boardvisorData.userData.split('|');
          if (boardvisorArr && boardvisorArr.length > 7 && boardvisorArr[7] && boardvisorArr[7] !== "undefined") {
            const languageCode = boardvisorArr[7];
            translate.setDefaultLang('fr');
            translate.use(languageCode || 'fr');
            return languageCode;
          }
        }

        translate.setDefaultLang('fr');
        translate.use('fr');
        return 'fr';
      }
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    }
    ],
})
export class CoreModule {
}
